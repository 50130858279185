<template>
  <div class="wrap item-pick">
    <header class="layout_header">
      <div :class="`${isScroll} ? 'wwww': 'qqqq' hinner`">
        <nav class="gnb">
          <div class="left_header">
            <a class="menu fontEn on"> item pick </a>
            <router-link class="menu fontEn" to="/pick/brand">
              brand pick
            </router-link>
          </div>
          <search-cart-icons />
        </nav>
      </div>
    </header>
    <div class="layout_body">
      <swiper :options="swiperOptions" class="sub_nav disflex">
        <swiper-slide v-for="item in folders" :key="item.key">
          <button
            :class="item.key === pickFolder ? 'all_btn_black' : 'make_folder'"
            type="button"
            @click="handleClickFolder(item.key)"
            v-text="item.label"
          />
        </swiper-slide>
        <template v-if="info">
          <swiper-slide>
            <button
              class="btn_pop_open plus_folder"
              type="button"
              @click="handleClickCreate"
            />
          </swiper-slide>
          <swiper-slide>
            <button
              class="folder_edit disblock"
              @click="handleClickEdit"
            ></button>
          </swiper-slide>
        </template>
      </swiper>

      <div
        v-if="items.length > 0"
        :class="`${isLoading ? '' : 'off'} inner ptb14`"
      >
        <article class="pick_item item">
          <div class="prod_swiper noswiper_prod p0">
            <ul>
              <li v-for="(item, idx) in items" :key="item.cit_id">
                <product-item
                  :key="idx"
                  :bookMark="true"
                  :item="item"
                  @update:bookmark="handleUpdateBookMark"
                >
                  <ks-check-box
                    slot="inImageUp"
                    :item="{ label: '' }"
                    :value="selectedItems.indexOf(item) > -1"
                    @click="handleSelectItem($event, item)"
                  />
                </product-item>
              </li>
            </ul>
          </div>
        </article>
      </div>

      <div v-else :class="`${isLoading ? '' : 'off'} noporduct`">
        {{ $t('Pick.NonePick') }}
      </div>

      <infinite-loading
        v-if="hasMore"
        :identifier="infiniteId"
        style="opacity: 0"
        @infinite="loadDatas"
      />
      <div
        v-if="selectedItems.length > 0"
        v-show="isLoading"
        class="pick_btn pc"
      >
        <ul v-if="!isAddToPreCart" class="disflex">
          <li class="folder_move" @click="handleClickMoveFolder">
            <button class="btn_pop_open">{{ $t('Pick.FolderMove') }}</button>
          </li>
          <li
            :class="{ gray_btn: selectedItems.length > 1 }"
            class="shop_basket"
            @click="addToPreCart"
          >
            <button>
              {{ $t('Basket.BasketButton') }}
            </button>
          </li>
        </ul>
        <ul v-else class="disflex">
          <li class="shop_basket" @click="addToCart">
            <button>
              {{ $t('Basket.BasketButton') }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="!isLoading" class="loading_wrap">
      <div class="loading_content">
        <img alt="loading" src="../../assets/images/design/loading.gif" />
      </div>
    </div>
    <sild-footer id="scroll" :isToggleNav="true" />

    <product-option-list
      v-if="product"
      v-show="isAddToPreCart"
      v-model="selectedOptions"
      :delivery="delivery"
      :product="product"
      @close="closeProductOption"
    />
    <create-folder
      v-if="isShowCreate"
      @close="isShowCreate = false"
      @create="handleConfirmFolder"
    />

    <sort-popup
      v-if="isShowMoveFolder"
      :items="folders.filter(item => item.key)"
      :title="$t('Pick.Folder')"
      noText="$t('Pick.NoneRegister')"
      @close="isShowMoveFolder = false"
      @select="handleSelectMoveFolder"
    />
    <edit-folder
      v-if="isShowEditFolder"
      @close="isShowEditFolder = false"
      @delete="handleDeleteFolder"
      @change:order="handleChangeFolderOrder"
    />
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue-demi';
import ProductAPI from '../../apis/ProductAPI';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import ProductItem from '../../components/ui/ProductItem/ProductItem.vue';
import CreateFolder from './popups/CreateFolder.vue';
import InfiniteLoading from 'vue-infinite-loading';
import ArrayUtils from '../../utils/ArrayUtils';
import KsCheckBox from '../../components/Inputs/KsCheckBox/KsCheckBox.vue';
import SortPopup from '../../components/popups/SortPopup.vue';
import useToast from '../../composables/dialogs/useToast';
import EditFolder from './popups/EditFolder.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import SearchCartIcons from '../../components/layouts/components/headers/components/RightHeader/SearchCartIcons.vue';
import useConfirm from '../../composables/dialogs/useConfirm';
import ProductOptionList from '../../components/ui/ProductOptionList/ProductOptionList.vue';

export default {
  components: {
    SildFooter,
    ProductItem,
    CreateFolder,
    InfiniteLoading,
    KsCheckBox,
    SortPopup,
    EditFolder,
    Swiper,
    SwiperSlide,
    SearchCartIcons,
    ProductOptionList,
  },
  setup(_, context) {
    let isScroll = ref(false);
    const { info } = useUserInfo();
    const { addMsg } = useToast();
    const { showConfirm } = useConfirm();
    const state = reactive({
      items: [],
      selectedOptions: [],
      folders: [{ key: undefined, label: '전체' }],
      product: null,
      provider: null,
      delivery: null,
      pickFolder: undefined,
      hasMore: true,
      infiniteId: Date.now(),
      selectedItems: [],
      isShowCreate: false,
      isShowEditFolder: false,
      isShowMoveFolder: false,
      isLoading: false,
      isAddToPreCart: false,
    });

    const params = {
      page: 0,
      limit: 10,
      mem_id: info?.mem_id ?? 0,
    };

    const loadDatas = async attr => {
      const { data } = await ProductAPI.getMyPickItems({
        ...params,
        pick_folder: state.pickFolder,
      });

      state.items = [...state.items, ...data.result_data];
      if (data.result_data.length !== params.limit) {
        attr?.complete();
        state.hasMore = false;
      } else {
        params.page++;
        attr?.loaded();
      }
      state.isLoading = true;
    };

    const loadFolders = async () => {
      if (!info) return;
      const { data } = await ProductAPI.getMyFolders({
        mem_id: info?.mem_id ?? 0,
      });

      const folders = [{ key: undefined, label: '전체' }];

      data.result_data?.forEach(({ pick_folder }) => {
        folders.push({
          key: pick_folder,
          label: pick_folder,
        });
      });

      state.folders = folders;
    };

    loadFolders();

    const clearSearchParams = () => {
      params.page = 0;
      state.items = [];
      state.hasMore = true;
      state.infiniteId = Date.now();
    };
    return {
      info,
      isScroll,
      swiperOptions: {
        slidesPerView: 'auto',
        watchOverflow: true,
        observer: true,
        observeParents: true,
      },
      loadDatas,
      ...toRefs(state),
      async handleConfirmFolder(item) {
        const folders = [...state.folders];

        folders.push(item);

        state.folders = folders;

        state.isShowCreate = false;
      },

      closeProductOption() {
        state.isAddToPreCart = false;
        state.selectedOptions = [];
        state.product = null;
        state.delivery = null;
        state.provider = null;
      },

      addToPreCart() {
        if (state.selectedItems.length === 0) {
          addMsg(context.root.$t('Pick.AlertSelect'));
          return;
        }
        if (state.selectedItems.length > 1) {
          addMsg(context.root.$t('Pick.AlertBasketMultipleItems'));
          return;
        }

        state.isAddToPreCart = true;

        const createProductData = data => {
          const product = { ...data };

          const sale = product.cit_sale_price;
          const price = product.cit_price;
          const percent = 100 - Math.floor((sale / price) * 100);

          product.percent = percent;

          return product;
        };

        const loadProduct = async () => {
          const { data } = await ProductAPI.getProduct(
            state.selectedItems[0].cit_id,
          );

          state.product = createProductData(data.result_data);
          state.provider = data.provider;
          state.delivery = data.delivery;
        };

        loadProduct();
      },

      async addToCart() {
        if (state.selectedOptions.length === 0) {
          addMsg(context.root.$t('Pick.AlertSelect'));
          return;
        }

        let items = localStorage.getItem('basket');

        items = items ? JSON.parse(items) : [];

        let isPush = true;

        if (isPush) {
          state.selectedOptions.map(value => {
            items.map(item => {
              if (value.cde_id === item.cde_id) {
                item.qty = item.qty + value.qty;
              }
            });
            items.push(...items, value);
          });

          items = items.reduce(function (acc, v) {
            if (acc.findIndex(({ cde_id }) => cde_id === v.cde_id) === -1) {
              acc.push(v);
            }
            return acc;
          }, []);
          localStorage.setItem('basket', JSON.stringify(items));
          state.isAddToPreCart = false;
          state.selectedOptions = [];
          state.product = null;
          state.delivery = null;
          state.provider = null;

          showConfirm(context.root.$t('Pick.AlertMove'), () => {
            context.root.$router.push('/cart');
          });

          state.headerKey = Date.now();
        } else {
          addMsg(context.root.$t('Pick.Duplication'));
        }
      },
      handleClickFolder(value) {
        state.pickFolder = value;
        state.selectedItems = [];

        clearSearchParams();
      },
      handleSelectItem(e, item) {
        e.stopPropagation();
        const items = [...state.selectedItems];

        const isPush = ArrayUtils.toggleByAttribute(items, item, 'cit_id');

        e.target.checked = isPush;

        state.selectedItems = items;
      },

      handleClickMoveFolder() {
        if (state.folders.length === 0) {
          addMsg(context.root.$t('Pick.NoneRegister'));
        } else {
          state.isShowMoveFolder = true;
        }
      },

      async handleSelectMoveFolder(item) {
        const ids = state.selectedItems.map(value => value.cit_id);

        await ProductAPI.moveFolder({
          mem_id: info?.mem_id ?? 0,
          ids,
          folder: item.key,
        });

        addMsg(context.root.$t('Pick.AlertToast', { Name: item.key }));

        state.isShowMoveFolder = false;

        setTimeout(() => {
          window.location.reload();
        }, 300);
      },

      handleUpdateBookMark(_, uItem) {
        state.items = [...state.items].filter(
          item => item.cit_id !== uItem.cit_id,
        );
      },

      handleDeleteFolder(folders) {
        state.folders = folders;
      },
      handleClickEdit(e) {
        e.preventDefault();

        state.isShowEditFolder = true;
      },

      handleChangeFolderOrder(folders) {
        state.folders = [
          { key: undefined, label: context.root.$t('Brand.TagAll') },
          ...folders,
        ];
      },
      handleClickCreate() {
        state.isShowCreate = true;
      },
    };
  },
};
</script>

<style>
.pick_btn .folder_move {
  border-right: 1px solid #202020;
}
.item-pick .swiper-slide {
  display: inline-block;
  width: auto;
}
.item-pick .btn_pop_open.plus_folder {
  background: url(~@/assets/images/design/PLUS.svg) no-repeat center;
  height: 30px;
  margin-left: 0px;
  width: 30px;
}
.item-pick .swiper-slide button {
  font-size: 12px;
}
.item-pick .folder_edit {
  background: url(~@/assets/images/design/ed.svg) no-repeat center;
  height: 30px;
  justify-self: flex-end;
  margin-left: 10px;
  width: 30px;
}
.main .layout_body {
  padding-top: 0;
}

.main .hinner {
  background-color: transparent;
  border-bottom: 0;
}
.main .hinner.bg {
  border-bottom: 1px solid #202020;
}
.loading_content {
  background: transparent;
  height: calc(100% - 90px);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.loading_content img {
  border-radius: 50em;
  height: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  z-index: 100;
}
@media (min-width: 1240px) {
  .loading_content {
    left: 50%;
    max-width: 360px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.off {
  opacity: 0;
}
.noporduct.off {
  opacity: 0;
}
</style>
