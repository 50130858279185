<template>
  <div class="layout_body">
    <back-title-basket :title="$t('Event.Event')" />
    <div class="inner evnet">
      <div class="event_img">
        <img :src="eventInfo.main_img" />
      </div>
      <p class="black_btn font4 fix_btn">{{ eventInfo.cbe_title }}</p>
    </div>
    <div class="inner pa14">
      <div class="prod_swiper noswiper_prod p0">
        {{ JSON.stringify(products) }}
        <ul>
          <li v-for="item in products" :key="item.cit_id">
            <product-item class="prod_img" :item="item" :bookMark="true" />
          </li>
        </ul>

        <!-- 쿠폰이 있을떄 보임 없으면 안보임 -->
        <!-- <div class="fixdownup">
                  <a href="" class="mini_coupon">
                      <span class="txt">스토어 쿠폰 다운로드 받기</span>
                      <span class="img"></span>
                  </a>
              </div> -->
      </div>
    </div>
    <SildFooter />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import ProductItem from '../../components/ui/ProductItem/ProductItem.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import EventAPI from '../../apis/EventAPI';

export default {
  components: {
    SildFooter,
    BackTitleBasket,
    ProductItem,
  },
  setup(_, context) {
    const { info } = useUserInfo();
    const id = context.root.$route.params.eventId;
    const state = reactive({
      eventInfo: {
        main_img: null,
      },
      products: [],
    });

    const loadDatas = async () => {
      try {
        const { data } = await EventAPI.getEventDetail({
          seq: id,
          mem_id: info?.mem_id,
        });
        state.eventInfo = {
          ...data.result_data,
          main_img: `${process.env.VUE_APP_FILE_URL}${data.result_data?.main_img}`,
        };
        state.products = data.result_data?.product ?? [];
      } catch (error) {
        console.log(error);
      }
    };

    loadDatas();
    // 스크롤 이벤트
    const handleEventTitle = () => {
      let header = document.querySelector('.fix_btn');

      window.addEventListener('scroll', function () {
        const windowTop = window.scrollY;

        if (windowTop > 0) {
          header.classList.add('fixed');
        } else {
          header.classList.remove('fixed');
        }
      });
    };

    onMounted(() => {
      handleEventTitle();
    });

    return {
      ...toRefs(state),
      handleEventTitle,
    };
  },
};
</script>

<style></style>
