<template>
  <down-up-popup
    :title="$t('Pick.FolderRename')"
    @close="$emit('close')"
    @confirm="handleConfirmFolder"
  >
    <template slot="body">
      <input
        v-model="etc"
        class="pick_search"
        type="search"
        :placeholder="$t('Pick.AlertName')"
      />
    </template>
  </down-up-popup>
</template>

<script>
import DownUpPopup from '@/components/popups/DownUpPopup.vue';
import { ref } from 'vue-demi';
import ProductAPI from '../../../apis/ProductAPI';
import useToast from '../../../composables/dialogs/useToast';
import useUserInfo from '../../../composables/user/useUserInfo';

export default {
  components: { DownUpPopup },
  props: ['value'],
  setup(props, context) {
    const etc = ref(props.value);

    const { addMsg } = useToast();
    const { info } = useUserInfo();

    return {
      etc,
      async handleConfirmFolder() {
        const { data } = await ProductAPI.updateFolderName({
          mem_id: info?.mem_id ?? 0,
          folder: etc.value,
          old_folder: props.value,
        });

        if (data.result === false) {
          addMsg(data.msg);
          return;
        }

        context.emit('update', etc.value, props.value);

        addMsg(context.root.$t('Pick.AlertEdit'));
      },
    };
  },
};
</script>

<style></style>
