<template>
  <div class="wrap">
    <header class="layout_header">
      <div class="hinner">
        <nav class="gnb">
          <div class="left_header">
            <router-link to="/pick" class="menu fontEn">
              item pick
            </router-link>
            <a class="menu fontEn on"> brand pick </a>
          </div>
          <search-cart-icons />
        </nav>
      </div>
    </header>
    <div v-if="isLoading" class="layout_body">
      <div class="inner p04">
        <section class="ranking_list_wrap">
          <ul v-if="brands.length">
            <brand-item-2
              v-for="item in brands"
              :key="`${item.den_id}-${item.count}`"
              :item="item"
              @update:bookmark="handleUpdateItem"
            />
          </ul>
          <div v-else :class="`${isLoading ? '' : 'off'} noporduct`">
            {{ $t('Pick.AlertNoneBrand') }}
          </div>
        </section>
      </div>
    </div>
    <div v-else class="loading_wrap">
      <div class="loading_content">
        <img src="../../assets/images/design/loading.gif" alt="loading" />
      </div>
    </div>
    <sild-footer />
  </div>
</template>

<script>
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BrandAPI from '@/apis/BrandAPI';
import { reactive, toRefs } from 'vue-demi';
import useUserInfo from '../../composables/user/useUserInfo';
import SearchCartIcons from '../../components/layouts/components/headers/components/RightHeader/SearchCartIcons.vue';
import BrandItem2 from '../../components/ui/BrandItem/BrandItem2.vue';

export default {
  components: { SildFooter, SearchCartIcons, BrandItem2 },
  setup() {
    const { info } = useUserInfo();

    const state = reactive({
      brands: [],
      isLoading: false,
    });

    const loadBrands = async () => {
      const { data } = await BrandAPI.getMyPickBrands({
        mem_id: info?.mem_id ?? 0,
      });

      state.brands = data.result_data;
      state.isLoading = true;
    };

    loadBrands();

    return {
      ...toRefs(state),
      handleUpdateItem(uItem) {
        state.brands = [...state.brands].filter(
          item => item.den_id !== uItem.den_id,
        );
      },
      handleCloseNewBrand() {
        state.isShowNewBrand = false;
        loadBrands();
      },
    };
  },
};
</script>

<style>
.main .layout_body {
  padding-top: 0;
}

.main .hinner {
  background-color: transparent;
  border-bottom: 0;
}
.main .hinner.bg {
  border-bottom: 1px solid #202020;
}
.loading_content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 90px);
  background: transparent;
  z-index: 99;
}
.loading_content img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 50em;
}
@media (min-width: 1240px) {
  .loading_content {
    max-width: 360px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.noporduct.off {
  opacity: 0;
}
</style>
