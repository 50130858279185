<template>
  <div>
    <down-full-up-popup :title="$t('Pick.FolderEdit')" @close="closeAction()">
      <button
        slot="rightBottom"
        type="button"
        class="add btn_dim_add btn_pop_open"
        @click="isShowCreate = true"
      />
      <div slot="body">
        <draggable
          tag="ul"
          :list="folders"
          class="folder_edit_list list-group"
          handle=".position_move"
          @change="handleChangeItem"
        >
          <template v-for="item in folders">
            <li v-if="item.key" :key="item.key" class="disflex list-group-item">
              <button
                type="button"
                class="btn_pop_open delete"
                @click="handleClickDelete(item)"
              />
              <a
                class="btn_pop_open"
                @click="handleClickUpdateName($event, item)"
              >
                {{ item.label }}
              </a>
              <div class="position_move"></div>
              <!-- <button type="button" class="position_move" /> -->
            </li>
          </template>
        </draggable>
      </div>
    </down-full-up-popup>

    <update-folder-name
      v-if="isShowUpdateFolderName"
      :value="selectedItem"
      @close="isShowUpdateFolderName = false"
      @update="handleUpdateFolderName"
    />
    <create-folder
      v-if="isShowCreate"
      @close="isShowCreate = false"
      @create="handleCreateFolder"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';
import CreateFolder from './CreateFolder.vue';
import ProductAPI from '../../../apis/ProductAPI';
import useUserInfo from '../../../composables/user/useUserInfo';
import useConfirm from '../../../composables/dialogs/useConfirm';
import useToast from '../../../composables/dialogs/useToast';
import UpdateFolderName from './UpdateFolderName.vue';
import draggable from 'vuedraggable';

export default {
  components: { DownFullUpPopup, CreateFolder, UpdateFolderName, draggable },
  setup(_, context) {
    const { info } = useUserInfo();
    const { addMsg } = useToast();
    const { showConfirm } = useConfirm();
    const state = reactive({
      folders: [],
      selectedItem: '',
      isShowCreate: false,
      isShowUpdateFolderName: false,
    });

    const loadFolders = async () => {
      const { data } = await ProductAPI.getMyFolders({
        mem_id: info?.mem_id ?? 0,
      });

      const folders = [];

      data.result_data?.forEach(({ pick_folder, pick_id, folder_order }) => {
        folders.push({
          pick_id: pick_id,
          key: pick_folder,
          label: pick_folder,
          folder_order: folder_order,
        });
      });

      state.folders = folders;
    };

    loadFolders();

    const deleteFolder = async item => {
      await ProductAPI.deleteFolder({
        mem_id: info?.mem_id ?? 0,
        folder: item.key,
      });

      state.folders = state.folders.filter(folder => folder !== item);

      addMsg(context.root.$t('Pick.AlertDelete'));

      context.emit('delete', state.folders);
    };

    return {
      ...toRefs(state),
      closeAction() {
        context.emit(`close`);
        window.location.reload();
      },
      handleCreateFolder(item) {
        const folders = [...state.folders];

        folders.push(item);

        state.folders = folders;

        state.isShowCreate = false;
      },

      handleClickDelete(item) {
        showConfirm(context.root.$t('Pick.AlertConfirm'), () => {
          deleteFolder(item);
        });
      },

      handleClickUpdateName(e, item) {
        e.preventDefault();

        state.selectedItem = item.key;

        state.isShowUpdateFolderName = true;
      },

      handleUpdateFolderName(newName, oldName) {
        const folders = [...state.folders];
        const idx = folders.findIndex(item => item.key === oldName);

        if (idx > -1) {
          folders[idx] = { key: newName, label: newName };
        }

        state.folders = folders;
        state.isShowUpdateFolderName = false;
      },

      async handleChangeItem() {
        const folders = state.folders.map((folder, idx) => ({
          ...folder,
          folder_order: idx,
        }));

        await ProductAPI.updateFolderOrder({
          folders: JSON.stringify(folders),
        });

        context.emit('change:order', folders);
      },
    };
  },
};
</script>

<style></style>
