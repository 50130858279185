<template>
  <li v-if="pickBrands" class="disflex brand-item-2">
    <div>
      <router-link :to="`/brand/detail/${brand.den_id}`">
        <image-bg-src
          :src="brand.img_url"
          class="profile_img"
          style="width: 30px; height: 30px"
        />
        <div class="profile_user">
          <h3 class="name">{{ brand.den_name }}</h3>
          <p class="desc">
            {{ brand.provider_concept1 == null ? '' : brand.provider_concept1 }}
            {{
              brand.provider_concept2 == null
                ? ''
                : `, ${brand.provider_concept2}`
            }}
          </p>
        </div>
      </router-link>
    </div>
    <p>
      <book-mark-button
        :key="isPick"
        class="list"
        type="p"
        :pickItemId="brand.den_id"
        :value="isPick"
        @update="handleClickBookMark"
      >
        {{ brand.count }}
      </book-mark-button>
    </p>
  </li>
</template>

<script>
import { ref, watch } from 'vue-demi';
import BookMarkButton from '../Buttons/BookMarkButton.vue';
import ImageBgSrc from '../ImageBlock/ImageBgSrc.vue';
import usePick from '@/composables/user/usePick';

export default {
  props: ['item', 'idx'],
  components: { ImageBgSrc, BookMarkButton },
  setup(props, context) {
    const { getUserPick, isPick: isPickBrands } = usePick();
    const isPick = ref(false);
    const pickBrands = ref(null);
    const brand = ref(props.item);

    const loadPickBrands = async () => {
      const brands = await getUserPick('brands');

      pickBrands.value = brands;

      isPick.value = isPickBrands('brands', props.item.den_id);
    };

    loadPickBrands();

    watch(
      () => props.item,
      () => {
        brand.value = props.item;
      },
    );

    return {
      brand,
      isPick,
      pickBrands,
      handleClickBookMark(checked) {
        const newItem = { ...brand.value };

        isPick.value = checked;

        newItem.count = Number(newItem.count) + (checked ? 1 : -1);

        brand.value = newItem;

        context.emit('update:bookmark', brand.value);
      },
    };
  },
};
</script>

<style scoped>
.brand-item-2 {
  font-size: 12px;
  justify-content: space-between;
}

.brand-item-2 .profile_img {
  width: 30px;
  height: 30px;
  border-radius: 50em;
  background: no-repeat center;
  vertical-align: middle;
  margin-right: 14px;
  background-size: cover;
  position: relative;
}
.brand-item-2 .profile_user .name {
  line-height: 17px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  font-size: 12px;
  font-weight: 500;
}
.brand-item-2 .profile_user .desc {
  line-height: 17px;
  color: #a8a8a8;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  font-size: 12px;
}
</style>
